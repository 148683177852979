import { parseTemplate } from 'url-template';

export const API_METHODS = {
	GET: 'GET',
	POST: 'POST',
	PATCH: 'PATCH',
	PUT: 'PUT',
	DELETE: 'DELETE',
} as const;

export const API_ROUTES = {
	//ACTIVITIES
	activity: parseTemplate('activity/{type}/{id}'),

	//REINFORCEMENT
	reinforcement: parseTemplate('reinforcements/{id}'),
	reinforcementAllActivities: parseTemplate('reinforcements/{id}/activities'),

	//REINFORCEMENT ACTIVITY
	reinforcementActivity: parseTemplate('reinforcements/activity/{type}/{id}'),
	reinforcementCourseQuizzes: parseTemplate('reinforcement/course/{courseId}'),
	reinforcementProficiency: 'reinforcements/proficiency',

	//LEARNING PATHS:
	learningPaths: parseTemplate(`reinforcements/{reinforcementId}/learning-paths?page={page}&limit=5`),
	learningPath: parseTemplate(`reinforcements/{reinforcementId}/learning-paths/{id}`),
	updateLearningPathStatus: parseTemplate(`reinforcements/{reinforcementId}/learning-paths/status/`),

	//COURSE
	course: parseTemplate('courses/{id}'),

	//LEARNER REINFORCEMENT ACTIVITY
	learnerReinforcementActivity: parseTemplate(`learner/reinforcements/{reinforcementID}/activities/{activityID}`),
	learnerReinforcementActivityTakeLater: parseTemplate(
		`learner/reinforcements/{reinforcementID}/activities/{activityID}/later`
	),
	learnerReinforcementActivityCheckStarted: parseTemplate(
		`learner/reinforcements/{reinforcementID}/activities/started/check`
	),
	submitQuiz: 'learner/activity/quizzes/submit?is_last=true',
	submitAssignment: 'learner/activity/assignments',
	draftAssignment: parseTemplate('learner/activity/assignments/submission/{id}'),
	dragDropAssignment: 'learner/activity/drag-and-drops',
	scrambleSubmit: 'learner/activity/scramble-sentences',
	proficiencyTest: parseTemplate('reinforcements/proficiency/{reinforcementId}'),
	updateProficiency: 'reinforcements/proficiency',
	quizStart: 'learner/activity/quizzes/start',
	submitOrdering: 'learner/activity/reorderings',

	//REPORTS
	heatmap: parseTemplate('reinforcement-reports/heatmap/{courseId}?filter={filter}&page={page}'),
	summary: parseTemplate('reinforcement-reports/summary/{courseId}?search={search}&page={page}'),
	leaderboard: parseTemplate(
		'reinforcement-reports/leaderboard/{courseId}?search={search}&page={page}&type={type}&filterId={filterId}'
	),
	proficiency: parseTemplate('reinforcement-reports/proficiency/{courseId}?search={search}&page={page}'),
	engagement: parseTemplate(
		'reinforcement-reports/engagement/{courseId}?search={search}&page={page}&statuses={statuses}&filterId={filterId}'
	),
	getCourseActivities: parseTemplate('reinforcement-reports/activity/{courseId}'),
	getCourseLearningObjectives: parseTemplate('reinforcement-reports/learning/{courseId}'),
	getCourseCompetencies: parseTemplate('reinforcement-reports/competency/{courseId}'),
	summaryActivity: parseTemplate(
		'reinforcement-reports/summary-activity/{activity}?search={search}&page={page}&statuses={statuses}'
	),
	reportAdminProficiencyQuiz: parseTemplate(
		'reinforcement-reports/proficiency-quiz/{proficiencyId}?userId={userId}&completionId={completionId}'
	),
	reportEvaluationResult: parseTemplate(
		'reinforcement-reports/response/{activityId}?userId={userId}&completionId={completionId}&type={type}'
	),
	reportResultAttempts: parseTemplate('reinforcement-reports/completions/{activityId}'),
	learnerCourses: parseTemplate('reinforcement-reports-learner/courses?search={search}&page={page}&limit=10'),
	learnerSummary: parseTemplate(
		'reinforcement-reports-learner/summary-activity/{courseId}?search={search}&page={page}'
	),
	learnerHeatmap: parseTemplate('reinforcement-reports-learner/heatmap/{courseId}?filter={filter}'),
	learnerLeaderboard: parseTemplate(
		'reinforcement-reports-learner/leaderboard/{courseId}?search={search}&page={page}&type={type}&filterId={filterId}'
	),
	learnerProficiency: parseTemplate('reinforcement-reports-learner/proficiency/{courseId}'),
	//EXPORT LR REPORTS
	exportLeaderboard: parseTemplate('reinforcement-export/leaderboard/{courseId}'),
	exportEngagement: parseTemplate('reinforcement-export/engagement/{courseId}'),
	exportHeatmap: parseTemplate('reinforcement-export/heat-map/{courseId}'),
	adminExportSummary: parseTemplate('reinforcement-export/summary/{courseId}'),
	adminExportSummaryActivity: parseTemplate('reinforcement-export/summary-activity/{reinforcementActivityId}'),
	learnerExportSummary: parseTemplate('reinforcement-export/summary-activity-learner/{courseId}'),
	exportProficiency: parseTemplate('reinforcement-export/proficiency/{courseId}'),
	exportProficiencyQuiz: parseTemplate('reinforcement-export/proficiency/{id}'), //learner - courseId, admin - proficiencyId

	// REPORT LEARNER
	reportCourses: parseTemplate('learner/report/courses?search={search}&page={page}&limit=10'),
	reportAssignment: parseTemplate('learner/report/assignments?search={search}&page={page}&limit=10'),

	// LEARNER COURSE
	courseStatus: parseTemplate('learner/courses/{courseId}/status'),
	startActivity: 'learner/courses/start/activity',
	courseDetail: parseTemplate('learner/courses/{courseId}'),

	// SCORM LEARNER
	startScormModule: parseTemplate('scorm-new/learner/start/{scormId}'),
	completeScormModule: 'scorm-new/learner/completed',
	completeScormActivity: 'scorm-new/learner/completed/activity',

	// ASSIGNMENT LEARNER
	assignmentUpdateSubmission: parseTemplate('learner/activity/assignments/submission/{edit}'),
	assignmentUploadTemporaryFile: 'learner/activity/assignments/upload/files',
} as const;
